import html2canvas from "html2canvas";
import * as fileSaver from "file-saver";
import base64ToBlob from "b64-to-blob";
const contentTypes: any = {
  png: "image/png",
  jpeg: "image/jpeg",
  jpg: "image/jpg",
};

/* various allowed base64 prepends, these are prepended to the start of a base64 image string */
const base64Prepends: any = {
  png: `data:${contentTypes.png};base64`,
  jpeg: `data:${contentTypes.jpeg};base64`,
  jpg: `data:${contentTypes.jpg};base64`,
};

const saveAs = (blob: any, fileName: any) => {
  var elem: any = window.document.createElement("a");
  elem.href = blob;
  elem.download = fileName;
  elem.style = "display:none;";
  (document.body || document.documentElement).appendChild(elem);
  if (typeof elem.click === "function") {
    elem.click();
  } else {
    elem.target = "_blank";
    elem.dispatchEvent(
      new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: true,
      })
    );
  }
  URL.revokeObjectURL(elem.href);
  elem.remove();
};

const exportAsPicture = (element: any) => {
  return new Promise((resolve, reject) => {
    html2canvas(element.current)
      .then((canvas) => {
        var image = canvas.toDataURL("image/png", 1.0);
        saveAs(image, "exported-vis.png");
        resolve(image);
      })
      .catch(() => {
        reject();
      });
  });
};

export function downloadImage(base64: string, name = "softcloud-download") {
  const ext = getExtFromBase64(base64);

  // if the getExtFromBase64 method doesn't throw, we have a valid extension!
  const prepend = base64Prepends[ext];
  const contentType = contentTypes[ext];
  const cleanedBase64 = base64.replace(`${prepend},`, "");

  // generate a blob, then a file and then save the file.
  const blob = base64ToBlob(cleanedBase64, contentType);
  const file = new File([blob], `${name}.${ext}`, { type: prepend });
  fileSaver.saveAs(file);
}

export function getExtFromBase64(base64: any) {
  let ext;
  if (typeof base64 === "string") {
    ext = Object.keys(base64Prepends).find(
      (key) => base64.indexOf(base64Prepends[key]) === 0
    );
  }

  // if extension was found, return it, otherwise throw.
  if (ext) {
    return ext;
  } else {
    throw new Error(
      `props.base64 on <Base64Downloader/> has invalid or undefined extension. expected ${Object.keys(
        contentTypes
      ).join(", ")}`
    );
  }
}
export default exportAsPicture;
