import { Image, Button, Text } from "@mantine/core";
function ProductHunt() {
  return (
    <a
      href="https://www.producthunt.com/products/background-remover?utm_source=badge-follow&utm_medium=badge&utm_souce=badge-background&#0045;remover"
      target="_blank"
    >
      <img
        src="https://api.producthunt.com/widgets/embed-image/v1/follow.svg?post_id=500333&theme=light"
        alt="Background&#0032;Remover - Free&#0032;Online&#0032;tool&#0032;to&#0032;remove&#0032;background&#0032;from&#0032;image&#0032; | Product Hunt"
        width="200"
        height="54"
      />
    </a>
  );
}
export default ProductHunt;
