import React, { useEffect, useRef, useState } from "react";
import { MantineProvider } from "@mantine/core";
import { removeBgFromFile } from "./helpers/background.helper";
import "./App.css";

import SampleImage from "./components/sample/sample.image.cmp";
import patterns from "./components/patterns/patters.helper";
import { backgroundsImages } from "./components/background/background.const";
import { normaliseDimensions } from "./helpers/image.helper";
import UploadComponent from "./components/uploader/uploader.cmp";
import applogo from "./assets/applogo.png";
import imgTransparent from "./assets/transparent.png";
import FooterComponent from "./components/footer/footer.component";
import BuymeButton from "./components/buyme/buyme.button";
import PrivacyPolicyPage from "./components/terms/privacy-policy";
import TermsConditionPage from "./components/terms/terms-and-conditions";
import ProductHunt from "./components/producthunt/producthunt.button";
import { Text } from "@mantine/core";
const MAX_IMAGE_SIDE = 200;

export default function App() {
  const [imageSrc, setImageSrc] = useState<string>("");
  const [imageLoading, setimageLoading] = useState<boolean>(false);
  const [imageDim, setImageDim] = useState<any>({ w: 0, h: 0 });
  const [imageTr, setImageTr] = useState<string>("1, 0, 0, 1, 0, 0");
  const [backGround, setbackGround] = useState<string>(
    "linear-gradient(to right, rgb(22, 160, 133), rgb(244, 208, 63));"
  );

  const handleInputChange = async (files: any) => {
    setimageLoading(true);
    const result: any = await removeBgFromFile(files[0]);
    const dimensions = normaliseDimensions(MAX_IMAGE_SIDE, result.dimension);
    debugger;
    setImageDim(dimensions);
    setImageSrc(result.image);
    setimageLoading(false);
  };

  const reset = () => {
    setImageSrc("");
    setImageDim({ w: 0, h: 0 });
  };

  const renderMain = () => {
    return (
      <MantineProvider withGlobalStyles withNormalizeCSS>
        <div className="App">
          <div className={"header-app"} style={{ background: backGround }}>
            <img className="header-app-title" src={applogo} />
            <div className="header-button-buy">
              <BuymeButton />
            </div>
            <div className="header-product-hunt">
              <ProductHunt />
            </div>

            <div className="sample-container">
              {/* <img className="img-side-left" src={sideLeftImg} />
              <img className="img-side-right" src={siderightImg} /> */}
              <UploadComponent
                imageLoading={imageLoading}
                onChange={handleInputChange}
                imageSrc={imageSrc}
                reset={reset}
              />
            </div>
          </div>
          <div className="explanationContainer">
            <img src={imgTransparent} width={600} />
            <div className="explanationContainerText">
              <Text size="lg" weight={700}>
                How does it works?
              </Text>
              <ul>
                <li>Select or Drag and Drop an image containing a human</li>
                <li>
                  Once the image is loaded the application runs an AI routine
                  that is able to recognize the face and body and removes the
                  background
                </li>
                <li>It removes the background and creates a new image</li>
                <li>
                  Now you can download the new image without the background
                </li>
              </ul>
              <Text size="lg" weight={700}>
                Enjoy!
              </Text>
            </div>
          </div>
          <div>
            <FooterComponent />
          </div>
        </div>
      </MantineProvider>
    );
  };

  const renderApp = () => {
    const path = window.location.pathname;
    switch (path) {
      case "/terms-and-conditions":
        return <TermsConditionPage />;
      case "/privacy-policy":
        return <PrivacyPolicyPage />;

      default:
        return renderMain();
    }
  };

  return renderApp();
}
