import { Container, Title, Text, Space } from "@mantine/core";

export default function PrivacyPolicyPage() {
  return (
    <>
      <Container size={800}>
        <Space h="md" />
        <Title order={1}> Privacy Policy</Title>
        <Space h="md" />
        <Text>
          Your privacy is important to us. It is SoftCloud policy to respect
          your privacy and comply with any applicable law and regulation
          regarding any personal information we may collect about you, including
          across our website, https://profile.softcloud.pro/, and other sites we
          own and operate.
        </Text>
        <Text>
          This policy is effective as of 26 July 2022 and was last updated on 26
          July 2022.
        </Text>
        <Title order={2}>For Site Visitors</Title>
        <Space h="md" />
        <Title order={3}>Information We Collect</Title>
        <Text>
          When you visit our website Softcloud.pro, our servers may
          automatically log the standard data provided by your web browser. It
          may include your device’s Internet Protocol (IP) address, your browser
          type and version, the pages you visit, the time and date of your
          visit, the time spent on each page, other details about your visit,
          and technical details that occur in conjunction with any errors you
          may encounter. Please be aware that while this information may not be
          personally identifying by itself, it may be possible to combine it
          with other data to personally identify individual persons.
        </Text>
        <Space h="md" />
        <Text>
          Use of Cookies. We use “cookies” to collect information about you and
          your activity across our site. A cookie is a small piece of data that
          our website stores on your computer, and accesses each time you visit,
          so we can understand how you use our site. This helps us serve you
          content based on preferences you have specified.
        </Text>
        <Space h="md" />
        <Title order={3}>
          Legitimate Reasons for Processing Your Personal Information
        </Title>
        <Space h="md" />
        <Text>
          We only collect and use your personal information when we have a
          legitimate reason for doing so. In which instance, we only collect
          personal information that is reasonably necessary to provide our
          services to you.
        </Text>
        <Space h="md" />
        <Title order={3}>Collection and Use of Information</Title>
        <Space h="md" />
        <Text>
          We may collect personal information from you when you do any of the
          following on our website:
        </Text>
        <Container>
          <ul>
            <li>
              <span>
                Use a mobile device or web browser to access our content.
              </span>
            </li>
            <li>
              <span>
                Contact us via email, social media, or on any similar
                technologies.
              </span>
            </li>
            <li>
              <span>When you mention us on social media.</span>
            </li>
          </ul>
        </Container>
        <Space h="md" />
        <Text>
          We may collect, hold, use, and disclose information for the following
          purposes, and personal information will not be further processed in a
          manner that is incompatible with these purposes:
        </Text>
        <Container>
          <ul>
            <li>
              <span>To consider your employment application.</span>
            </li>
            <li>
              <span>
                To enable you to access and use our website, associated
                applications, and associated social media platforms.
              </span>
            </li>
            <li>
              <span>
                For internal record keeping and administrative purposes.
              </span>
            </li>
            <li>
              <span>
                For security and fraud prevention, and to ensure that our sites
                and apps are safe, secure, and used in line with our terms of
                use.
              </span>
            </li>
          </ul>
        </Container>
        <Space h="md" />
        <Text>
          Please be aware that we may combine information we collect about you
          with general information or research data we receive from other
          trusted sources.
        </Text>
        <Space h="md" />
        <Title order={3}>Security of Your Personal Information</Title>
        <Space h="md" />
        <Text>
          When we collect and process personal information, and while we retain
          this information, we will protect it within commercially acceptable
          means to prevent loss and theft, as well as unauthorized access,
          disclosure, copying, use, or modification.
        </Text>
        <Space h="md" />
        <Text>
          Although we will do our best to protect the personal information you
          provide to us, we advise that no method of electronic transmission or
          storage is 100% secure, and no one can guarantee absolute data
          security. We will comply with laws applicable to us in respect of any
          data breach.
        </Text>
        <Space h="md" />
        <Text>
          You are responsible for selecting any password and its overall
          security strength, ensuring the security of your own information
          within the bounds of our services.
        </Text>
        <Space h="md" />
        <Title order={3}>How Long We Keep Your Personal Information</Title>
        <Space h="md" />
        <Text>
          We keep your personal information only for as long as we need to. This
          time period may depend on what we are using your information for, in
          accordance with this privacy policy. If your personal information is
          no longer required, we will delete it or make it anonymous by removing
          all details that identify you.
        </Text>
        <Space h="md" />
        <Text>
          However, if necessary, we may retain your personal information for our
          compliance with a legal, accounting, or reporting obligation or for
          archiving purposes in the public interest, scientific, or historical
          research purposes or statistical purposes.
        </Text>
        <Space h="md" />
        <Title order={3}>Children’s Privacy</Title>
        <Space h="md" />
        <Text>
          We do not aim any of our products or services directly at children
          under the age of 13, and we do not knowingly collect personal
          information about children under 13.
        </Text>
        <Space h="md" />
        <Title order={3}>
          Disclosure of Personal Information to Third Parties
        </Title>
        <Space h="md" />
        <Text>We may disclose personal information to:</Text>
        <Container>
          <ul>
            <li>
              <span>A parent, subsidiary, or affiliate of our company.</span>
            </li>
            <li>
              <span>
                Third party service providers for the purpose of enabling them
                to provide their services, for example, IT service providers,
                data storage, hosting and server providers, advertisers, or
                analytics platforms
              </span>
            </li>
            <li>
              <span>Our employees, contractors, and/or related entities.</span>
            </li>
            <li>
              <span>
                Our existing or potential agents or business partners.
              </span>
            </li>
            <li>
              <span>
                Sponsors or promoters of any competition, sweepstakes, or
                promotion we run
              </span>
            </li>
            <li>
              <span>
                Courts, tribunals, regulatory authorities, and law enforcement
                officers, as required by law, in connection with any actual or
                prospective legal proceedings, or in order to establish,
                exercise, or defend our legal rights.
              </span>
            </li>
            <li>
              <span>
                Third parties, including agents or sub-contractors, who assist
                us in providing information, products, services, or direct
                marketing to you third parties to collect and process data
              </span>
            </li>
          </ul>
        </Container>
        <Space h="md" />
        <Title order={3}>International Transfers of Personal Information</Title>
        <Space h="md" />
        <Text>
          The personal information we collect is stored and/or processed where
          we or our partners, affiliates, and third-party providers maintain
          facilities. Please be aware that the locations to which we store,
          process, or transfer your personal information may not have the same
          data protection laws as the country in which you initially provided
          the information. If we transfer your personal information to third
          parties in other countries: (i) we will perform those transfers in
          accordance with the requirements of applicable law; and (ii) we will
          protect the transferred personal information in accordance with this
          privacy policy.
        </Text>
        <Space h="md" />
        <Title order={3}>
          Your Rights and Controlling Your Personal Information
        </Title>
        <Space h="md" />
        <Text>
          You always retain the right to withhold personal information from us,
          with the understanding that your experience of our website may be
          affected. We will not discriminate against you for exercising any of
          your rights over your personal information. If you do provide us with
          personal information you understand that we will collect, hold, use
          and disclose it in accordance with this privacy policy. You retain the
          right to request details of any personal information we hold about
          you.
        </Text>
        <Space h="md" />
        <Text>
          If we receive personal information about you from a third party, we
          will protect it as set out in this privacy policy. If you are a third
          party providing personal information about somebody else, you
          represent and warrant that you have such person’s consent to provide
          the personal information to us.
        </Text>
        <Space h="md" />
        <Text>
          If you have previously agreed to us using your personal information
          for direct marketing purposes, you may change your mind at any time.
          We will provide you with the ability to unsubscribe from our
          email-database or opt out of communications. Please be aware we may
          need to request specific information from you to help us confirm your
          identity.
        </Text>
        <Space h="md" />
        <Text>
          If you believe that any information we hold about you is inaccurate,
          out of date, incomplete, irrelevant, or misleading, please contact us
          using the details provided in this privacy policy. We will take
          reasonable steps to correct any information found to be inaccurate,
          incomplete, misleading, or out of date.
        </Text>
        <Space h="md" />
        <Text>
          If you believe that we have breached a relevant data protection law
          and wish to make a complaint, please contact us using the details
          below and provide us with full details of the alleged breach. We will
          promptly investigate your complaint and respond to you, in writing,
          setting out the outcome of our investigation and the steps we will
          take to deal with your complaint. You also have the right to contact a
          regulatory body or data protection authority in relation to your
          complaint.
        </Text>
        <Space h="md" />
        <Title order={3}>Use of Cookies</Title>
        <Space h="md" />
        <Text>
          We use “cookies” to collect information about you and your activity
          across our site. A cookie is a small piece of data that our website
          stores on your computer, and accesses each time you visit, so we can
          understand how you use our site. This helps us serve you content based
          on preferences you have specified.
        </Text>
        <Space h="md" />
        <Title order={3}>Limits of Our Policy</Title>
        <Space h="md" />
        <Text>
          Our website may link to external sites that are not operated by us.
          Please be aware that we have no control over the content and policies
          of those sites, and cannot accept responsibility or liability for
          their respective privacy practices.
        </Text>
        <Space h="md" />
        <Title order={3}>Changes to This Policy</Title>
        <Space h="md" />
        <Text>
          At our discretion, we may change our privacy policy to reflect updates
          to our business processes, current acceptable practices, or
          legislative or regulatory changes. If we decide to change this privacy
          policy, we will post the changes here at the same link by which you
          are accessing this privacy policy.
        </Text>
        <Space h="md" />
        <Text>
          If required by law, we will get your permission or give you the
          opportunity to opt in to or opt out of, as applicable, any new uses of
          your personal information.
        </Text>
        <Space h="md" />
        <Title order={3}>Contact Us</Title>
        <Space h="md" />
        <Text>
          For any questions or concerns regarding your privacy, you may contact
          us using the following details:
        </Text>
        <Space h="md" />
        <Text>admin@softcloud.pro</Text>
        <Space h="md" />
        <Text>https://profile.softcloud.pro/</Text>
        <Space h="md" />
        <Title order={2}>For UK and EU individuals</Title>
        <Space h="md" />
        <Text>
          This section applies solely to individuals in the UK and EU (for these
          purposes, reference to the EU also includes the European Economic Area
          countries of Iceland, Liechtenstein and Norway and, where applicable,
          Switzerland). Our Privacy Policy describes why and how CloudOps
          collects, uses and stores your Personal Data, the lawful basis on
          which your Personal Data is processed, and what your rights and our
          obligations are in relation to such processing (please see “Your
          Rights” section below).
        </Text>
        <Space h="md" />
        <Title order={3}>Data Controller</Title>
        <Space h="md" />
        <Text>
          CloudOps is the data controller for processing your Personal Data. The
          data controller is responsible for deciding how Personal Data about
          you is used. Please see the “Contact Us” section.
        </Text>
        <Space h="md" />
        <Title order={3}>Your Rights</Title>
        <Space h="md" />
        <Text>
          Subject to applicable EU and UK law, you have the following rights in
          relation to your Personal Data:
        </Text>
        <Container>
          <ul>
            <li>
              <span>
                Right of access. If you ask us, we will confirm whether we are
                processing your Personal Data and, if so, provide you with a
                copy of that Personal Data along with certain other details. If
                you require additional copies, we may need to charge a
                reasonable fee.
              </span>
            </li>
            <li>
              <span>
                Right to rectification. If your Personal Data is inaccurate or
                incomplete, you are entitled to ask that we correct or complete
                it. If we shared your Personal Data with others, we will tell
                them about the correction where possible. If you ask us, and
                where possible and lawful to do so, we will also tell you with
                whom we shared your Personal Data so you can contact them
                directly.
              </span>
            </li>
            <li>
              <span>
                Right to delete and erase your data. You may ask us to delete or
                remove your Personal Data, such as where you withdraw your
                consent. If we shared your data with others, we will tell them
                about the erasure where possible. If you ask us, and where
                possible and lawful to do so, we will also tell you with whom we
                shared your Personal Data with so you can contact them directly.
              </span>
            </li>
            <li>
              <span>
                Right to restrict processing. You may ask us to restrict or
                ‘block’ the processing of your Personal Data in certain
                circumstances, such as where you contest the accuracy of the
                data or object to us processing it. We will tell you before we
                lift any restriction on processing. If we shared your Personal
                Data with others, we will tell them about the restriction where
                possible. If you ask us, and where possible and lawful to do so,
                we will also tell you with whom we shared your Personal Data so
                you can contact them directly.
              </span>
            </li>
            <li>
              <span>
                Right to data portability. You have the right to obtain your
                Personal Data from us that you consented to give us or that was
                provided to us as necessary in connection with our contract with
                you. We will give you your Personal Data in a structured,
                commonly used and machine-readable format. You may reuse it
                elsewhere.
              </span>
            </li>
            <li>
              <span>
                Right to object. You may ask us at any time to stop processing
                your Personal Data, and we will do so: (1) If we are relying on
                a legitimate interest to process your Personal Data – unless we
                demonstrate compelling legitimate grounds for the processing or
                (2) If we are processing your Personal Data for direct
                marketing.
              </span>
            </li>
            <li>
              <span>
                Rights in relation to automated decision-making and profiling.
                You have the right to be free from decisions based solely on
                automated processing of your Personal Data, including profiling,
                which produce a significant legal effect on you, unless such
                profiling is necessary for entering into, or the performance of,
                a contract between you and us, or with your explicit consent. We
                are not currently processing your Personal Data for such type of
                automated decision-making, including profiling, but if we elect
                to do so in the future we will provide you with notice and
                choice, in accordance with EU or UK data protection law.
              </span>
            </li>
            <li>
              <span>
                Right to withdraw consent. If we rely on your consent to process
                your Personal Data, you have the right to withdraw that consent
                at any time, but this will not affect any processing of your
                data that has already taken place.
              </span>
            </li>
            <li>
              <span>
                Right to lodge a complaint with the data protection authority.
                If you have a concern about our privacy practices, including the
                way we handled your Personal Data, you can report it to the data
                protection authority that is authorized to hear those concerns.
              </span>
            </li>
          </ul>
        </Container>
        <Space h="md" />
        <Space h="md" />
        <Space h="md" />
        <Space h="md" />
        <Space h="md" />
      </Container>
    </>
  );
}
