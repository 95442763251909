import { useRef } from "react";
import { Text, Group, Button, createStyles } from "@mantine/core";
import { Dropzone, IMAGE_MIME_TYPE, MIME_TYPES } from "@mantine/dropzone";
import { IconCloudUpload, IconX, IconDownload } from "@tabler/icons";
import { ReactComponent as UploadSvg } from "./assets/uploadfile.svg";
import { ReactComponent as LoadSvg } from "./assets/loadfile.svg";
import { downloadImage } from "../../helpers/download.helper";
import "./uploader.cmp.css";

const useStyles = createStyles((theme) => ({
  wrapper: {
    position: "relative",
    marginBottom: 30,
  },

  dropzone: {
    borderWidth: 1,
    paddingBottom: 50,
  },

  icon: {
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[3]
        : theme.colors.gray[4],
  },

  control: {
    position: "absolute",
    width: 250,
    left: "calc(50% - 125px)",
    bottom: -20,
  },
  back: {
    position: "absolute",
    width: 250,
    left: "calc(50% - 125px)",
    bottom: -80,
  },
  downloadZone: {
    width: 404,
    height: 521,
    backgroundColor: "white",
    border: "1px dashed #ced4da",
    borderRadius: 8,
    padding: 50,
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center",
    height: "100%",
    backgroundImage: 'url("/assets/bgdamie.jpeg")',
    backgroundRepeat: "repeat",
    alignItems: "center",
    borderRadius: 8,
    border: "solid 1px grey",
  },
}));

export default function UploadCmp({
  onChange,
  imageLoading,
  imageSrc,
  reset,
}: {
  onChange: any;
  imageLoading: boolean;
  imageSrc: any | null;
  reset: any;
}) {
  const { classes, theme } = useStyles();
  const openRef = useRef<() => void>(null);
  const backHome = () => {
    reset();
  };
  const renderUpload = () => {
    return (
      <div className={classes.wrapper}>
        <Dropzone
          openRef={openRef}
          onDrop={(files) => onChange(files)}
          className={classes.dropzone}
          radius="md"
        >
          <div style={{ pointerEvents: "none" }}>
            <Group position="center">
              <Dropzone.Accept>
                <UploadSvg />
              </Dropzone.Accept>
              <Dropzone.Reject>
                <IconX size={50} color={theme.colors.red[6]} stroke={1.5} />
              </Dropzone.Reject>
              <Dropzone.Idle>
                {imageLoading ? <LoadSvg /> : <UploadSvg />}
              </Dropzone.Idle>
            </Group>

            <Text align="center" weight={700} size="lg" mt="xl">
              <Dropzone.Accept>Drop files here</Dropzone.Accept>
              <Dropzone.Idle>
                {imageLoading ? "Loading image ..." : "Upload image"}
              </Dropzone.Idle>
            </Text>
            <Text align="center" size="sm" mt="xs" color="dimmed">
              Drag&apos;n&apos;drop your profile image here to upload.
            </Text>
          </div>
        </Dropzone>

        <Button
          className={classes.control}
          size="md"
          radius="xl"
          onClick={() => openRef.current?.()}
        >
          Select files
        </Button>
      </div>
    );
  };

  const renderImage = () => {
    debugger;
    return (
      <div className={classes.wrapper}>
        <div className={classes.downloadZone}>
          <div className={classes.imageContainer}>
            <img width={200} src={imageSrc}></img>
          </div>
        </div>
        <Button
          className={classes.control}
          size="md"
          radius="xl"
          onClick={() => downloadImage(imageSrc)}
        >
          Download File
        </Button>
        <Button
          className={classes.back}
          size="md"
          radius="xl"
          onClick={backHome}
        >
          Back
        </Button>
      </div>
    );
  };

  return imageSrc ? renderImage() : renderUpload();
}
