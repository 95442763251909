import { Image, Button, Text } from "@mantine/core";
import bmcbuttonImg from "./assets/bmcButton.png";
function BuymeButton() {
  return (
    <a target="_blank" href="https://www.buymeacoffee.com/gquiman">
      <Image src={bmcbuttonImg} alt="Buy me a coffee" width={150} />
    </a>
  );
}
export default BuymeButton;
