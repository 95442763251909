import React from "react";
import { Grid, Group, List, Space, Text, Title } from "@mantine/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { Buymebutton } from "@gquiman/common.ui.buymebutton";
import {
  faFacebookSquare,
  faLinkedin,
  faTwitterSquare,
} from "@fortawesome/free-brands-svg-icons";
import useStyles from "./footer.styles";

const FooterComponent = () => {
  const { classes } = useStyles();
  return (
    <div className={classes.footer}>
      <Grid>
        <Grid.Col span={12} lg={4} className={classes.footerLogo}>
          <Group>
            <img
              src="/assets/logo-icon.png"
              alt="SoftCloudLogo Logo"
              className={classes.logo}
            />
            <Title order={1}> CloudOps</Title>
            {/* <Buymebutton /> */}
          </Group>

          <Group mt="xl" ml="sm" className={classes.socials}>
            <div>
              <a
                target="_blank"
                href="https://www.linkedin.com/in/guillermoquiros/"
                rel="noreferrer"
              >
                <FontAwesomeIcon
                  icon={faLinkedin}
                  className={classes.socialIcon}
                />
              </a>
            </div>
            <div>
              <a
                target="_blank"
                href="https://twitter.com/GQMMQG"
                rel="noreferrer"
              >
                <FontAwesomeIcon
                  icon={faTwitterSquare}
                  className={classes.socialIcon}
                />
              </a>
            </div>
            <div>
              <a
                target="_blank"
                href="https://www.facebook.com/quillo.gui"
                rel="noreferrer"
              >
                <FontAwesomeIcon
                  icon={faFacebookSquare}
                  className={classes.socialIcon}
                />
              </a>
            </div>
          </Group>
        </Grid.Col>
        <Grid.Col span={6} sm={3} lg={2}>
          <Title order={3}> Patners</Title>
          <Space h="lg" />
          <List listStyleType="none">
            <List.Item className={classes.listItem}>
              <a href="https://www.k8studio.io/">
                <a className={classes.link}>K8Studio</a>
              </a>
            </List.Item>
            <List.Item className={classes.listItem}>
              <a href="https://uxxu.io">
                <a className={classes.link}>UxxU Consulting</a>
              </a>
            </List.Item>
          </List>
        </Grid.Col>
        <Grid.Col span={6} sm={3} lg={2}>
          <Title order={3}>Other Apps</Title>
          <Space h="lg" />
          <List listStyleType="none">
            <List.Item className={classes.listItem}>
              <a href="https://profileme.uxxu.io/">
                <a className={classes.link}>Profile Picture Maker</a>
              </a>
            </List.Item>
          </List>
        </Grid.Col>
        <Grid.Col span={6} sm={3} lg={2}>
          <Title order={3}> Legal</Title>
          <Space h="lg" />
          <List listStyleType="none">
            <List.Item className={classes.listItem}>
              <a href="/terms-and-conditions">
                <a className={classes.link}>Terms & conditions</a>
              </a>
            </List.Item>

            <List.Item className={classes.listItem}>
              <a href="/privacy-policy">
                <a className={classes.link}>Privacy Policy</a>
              </a>
            </List.Item>
          </List>
        </Grid.Col>
        <Grid.Col span={6} sm={3} lg={2}>
          <Title order={3}> Contacts</Title>
          <Space h="lg" />
          <List listStyleType="none">
            <List.Item className={classes.listItem}>
              <Text className={classes.link}>admin@k8studio.io</Text>
            </List.Item>
          </List>
        </Grid.Col>
      </Grid>

      <Text align="right"> &copy; 2022 CloudOps </Text>
    </div>
  );
};
export default FooterComponent;
